import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";

import "../styles/programovanie-na-mieru.scss";
import ContactUs from "../components/contactUs";
import ProgramovanieSolutionBox from "../components/programovanie-na-mieru/programovanieSolutionBox";
import ZonaFaqQuestion from "../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../components/zakaznicka-zona/sliderSection.js";
import References from "../components/homepage/references.js";
import ZonaClankySlider from "../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import prvotnaKonzultaciaImg from "../images/webdizajn/webdizajn-konzultacia.jpg";
import planovanieImg from "../images/webdizajn/webdizajn-planovanie.jpg";
import navrhImg from "../images/webdizajn/webdizajn-navrh.jpg";
import vyvojImg from "../images/webdizajn/webdizajn-vyvoj-programovanie.jpg";
import testovanieImg from "../images/webdizajn/webdizajn-softverove-testovanie.jpg";
import spustenieImg from "../images/webdizajn/webdizajn-spustenie-webstranky-a-eshopu.jpg";
import udrzbaImg from "../images/webdizajn/webdizajn-servis-monitoring-a-udrzba.jpg";

import novePodnikanieIcon from "../images/webdizajn/icons/webdizajn-nove-podnikanie.svg";
import redizajnIcon from "../images/webdizajn/icons/webdizajn-redizajn.svg";
import rozsireniePodnikanieIcon from "../images/webdizajn/icons/webdizajn-rozsirenie-podnikania.svg";
import uxUiIcon from "../images/webdizajn/icons/webdizajn-ux-ui-rozsirenie.svg";
import seoIcon from "../images/webdizajn/icons/webdizajn-seo-a-marketing.svg";
import konkurenciaIcon from "../images/webdizajn/icons/webdizajn-zvysenie-konkurencieschopnosti.svg";
import brandingIcon from "../images/webdizajn/icons/webdizajn-branding-povedomie-o-znacke.svg";

//import zonesColage from "../images/zakaznicka-zona/okna.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "webdizajn-web-a-portaly" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const Webdizajn = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="programovanie-na-mieru-main">
      <SEO
        title="Webdizajn pre e-shopy a webstránky"
        description="V dnešnej dobe je kvalitný
        webdizajn kľúčovým faktorom úspechu každého online podnikania. Ako spoločnosť
        špecializujúca sa na vytváranie zákazníckych zón a programovanie na mieru, prinášame aj
        ponuku o služby v oblasti webdizajnu pre e-shopy a webstránky. Naším cieľom je poskytnúť
        vám komplexné riešenia, ktoré vám pomôžu zvýšiť tržby, konverzné pomery, zlepšiť
        používateľský zážitok a maximalizovať ROI."
      />
      <MainSectionZakaznickaZona
        heading="Webdizajn pre e-shopy a webstránky"
        subHeading="(Šablónový aj na mieru)"
        textHtml={
          <>
            V dnešnej dostronge je <b>kvalitný webdizajn kľúčovým faktorom</b>{" "}
            úspechu každého online <b>podnikania.</b> Ako spoločnosť
            špecializujúca sa na vytváranie zákazníckych zón a programovanie na
            mieru, <b>prinášame</b> aj ponuku o{" "}
            <b>služby v oblasti webdizajnu</b> pre <b>e-shopy</b> a{" "}
            <b>webstránky.</b> Naším cieľom je poskytnúť vám komplexné riešenia,
            ktoré vám <b>pomôžu zvýšiť tržby</b>, konverzné pomery,{" "}
            <b>zlepšiť</b> používateľský <b>zážitok a</b> maximalizovať{" "}
            <b>ROI.</b>
          </>
        }
      />
      <section id="what-is-client-zone">
        <ProgramovanieSolutionBox
          img={2}
          heading="Kedy najlepšie využijete webdizajn?"
          text={
            <>
              Webdizajn viete najlepšie využiť v rôznych fázach svojho
              podnikania a hneď z niekoľkých dôvodov. Častým je{" "}
              <b>vnútorný pocit, spätná väzba z okolia</b>, či „
              <b>škaredý dizajn</b>“.
            </>
          }
        />
      </section>

      {/*<section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Zákaznícke zóny"
          
        />
      </section>*/}

      <section className="section-blog">
        <div className="banner-blog">
          <h2>
            Dôvody a situácie, kedy je ideálne využiť webdizajn. Či už lacnejší
            šablónový alebo drahší, avšak výstižnejší na mieru.
          </h2>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={novePodnikanieIcon}
                alt="Webdizajn nové podnikanie"
                title="Webdizajn nové podnikanie"
              />
            </div>
          </div>
          <div>
            <h3>
              Nové podnikanie{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              <b>Ak</b> práve <b>začínate s podnikaním</b>, webová stránka je
              nevyhnutná. Je to vaša digitálna vizitka, ktorá vám umožní
              predstaviť sa potenciálnym zákazníkom, partnerom a investorom. V
              dnešnej dobe je online prítomnosť takmer povinná a bez webovej
              stránky riskujete, že budete prehliadaní. Webová <b>stránka</b>{" "}
              vám tiež <b>umožní efektívne komunikovať vašu značku, hodnoty</b>{" "}
              a <b>produkty</b> alebo <b>služby</b>, ktoré ponúkate.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={redizajnIcon}
                alt="Webdizajn redizajn"
                title="Webdizajn redizajn"
              />
            </div>
          </div>
          <div>
            <h3>
              Redizajn existujúcej stránky{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Technológie a trendy sa rýchlo menia. <b>Ak je</b> vaša webová{" "}
              <b>stránka zastaraná, môže</b> to <b>negatívne ovplyvniť</b> vašu{" "}
              <b>reputáciu a zákaznícku skúsenosť.</b> Webový{" "}
              <b>redizajn prinesie moderný vzhľad, zlepšenú navigáciu</b> a
              optimalizáciu pre mobilné zariadenia. Je to
              <b> investícia</b>, ktorá <b>prinesie vysoký</b> návratový{" "}
              <b>efekt</b>.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={rozsireniePodnikanieIcon}
                alt="Webdizajn rozšírenie podnikania"
                title="Webdizajn rozšírenie podnikania"
              />
            </div>
          </div>
          <div>
            <h3>
              Rozšírenie podnikania{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              <b>Ak sa</b> vaše <b>podnikanie rozrastá alebo mení</b>, je
              pravdepodobné, že budete potrebovať aktualizovať webovú stránku.
              Môže to byť
              <b> pridanie nových produktov, služieb</b> alebo celých{" "}
              <b>sekcií</b>. Aktualizovaný dizajn a štruktúra stránky{" "}
              <b>môžu pomôcť zákazníkom lepšie sa orientovať</b> a{" "}
              <b>rýchlejšie nájsť</b> to, <b>čo hľadajú</b>.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={uxUiIcon}
                alt="Webdizajn UI UX rozšírenie"
                title="Webdizajn UI UX rozšírenie"
              />
            </div>
          </div>
          <div>
            <h3>
              Zlepšenie používateľskej skúsenosti{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Vysoká miera odchodu alebo nízky konverzný pomer sú často
              signálmi, že vaša stránka nie je používateľsky prívetivá.
              <b>
                {" "}
                Investícia do UX/UI dizajnu zlepší interakciu zákazníkov
              </b>{" "}
              so stránkou, <b>čo vedie k vyšším</b> konverzným pomerom{" "}
              <b>(predajom)</b> a <b>spokojnejším zákazníkom</b>.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={seoIcon}
                alt="Webdizajn SEO a marketing"
                title="Webdizajn SEO a marketing"
              />
            </div>
          </div>
          <div>
            <h3>
              SEO a marketing{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Dobrý <b>webdizajn je kľúčový pre SEO. Stránka</b> optimalizovaná
              pre vyhľadávače <b>bude mať vyššiu šancu</b> objaviť sa na prvých
              pozíciách
              <b> vo výsledkoch Google vyhľadávania</b>, čo{" "}
              <b>priláka viac návštevníkov</b>. Toto je zásadné pre online
              marketing a <b>zvyšovanie povedomia o vašej značke</b>.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={konkurenciaIcon}
                alt="Webdizajn zvýšenie konkurencieschopnosti"
                title="Webdizajn zvýšenie konkurencieschopnosti"
              />
            </div>
          </div>
          <div>
            <h3>
              Zvýšenie konkurencieschopnosti{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              V konkurenčnom prostredí môže kvalitný webdizajn poskytnúť{" "}
              <b>výhodu oproti konkurencii</b>. Je to jeden z faktorov, ktorý{" "}
              <b>zákazníka presvedčí, aby si vybral</b> práve <b>vás</b>. Dobrý{" "}
              <b>webdizajn zvýši dôveru a </b>
              vnímanú <b>hodnotu vašej značky</b>.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={brandingIcon}
                alt="Webdizajn branding povedomie o značke"
                title="Webdizajn branding povedomie o značke"
              />
            </div>
          </div>
          <div>
            <h3>
              Zlepšenie brandingu{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              <b>
                Konzistentný a profesionálny webdizajn je kľúčový pre budovanie
                silnej značky. Pomáha vytvárať dôveru a zvyšuje hodnotu
              </b>{" "}
              vašej značky <b>v očiach zákazníkov</b>. Dobrý dizajn{" "}
              <b>komunikuje</b> vaše <b>hodnoty, misiu</b> a <b>viziu</b>, a tým{" "}
              <b>posilňuje</b> vašu <b>pozíciu na trhu</b>.
            </p>
          </div>
        </div>
      </section>
      <section id="online-business-solutions">
        <h2>Detailný proces webdizajnu webovej stránky, eshopu, či portálu</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={prvotnaKonzultaciaImg}
            alt="Webdizajn konzultácia"
            title="Webdizajn konzultácia"
          />
          <div className="solution-description">
            <h3>1. Konzultácia</h3>
            <p>
              V tejto fáze sa uskutoční osobný alebo online{" "}
              <b>rozhovor s klientom</b>. Cieľom je zistiť,{" "}
              <b>čo presne klient potrebuje</b> — aký typ stránky, aké funkcie,
              cieľovú skupinu a podobne. Táto <b>fáza je kľúčová</b> pre celkový
              úspech projektu.
            </p>
            {/*<br />
            <p className="bold">
              Prvé stretnutie je na nás a trvá 1 – 2 hodiny
            </p>*/}
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>2. Plánovanie</h3>
            <p>
              Po konzultácii nasleduje fáza plánovania.{" "}
              <b>Vytvorí sa podrobný plán</b>, ktorý zahŕňa{" "}
              <b>štruktúru stránky, typy obsahu</b>, ktoré budú potrebné, a{" "}
              <b>technológie</b>, ktoré sa použijú. Tento <b>plán</b> slúži ako
              <b> vodítko pre celý projekt</b>.
            </p>
          </div>
          <img
            className="img solution-img"
            src={planovanieImg}
            alt="Webdizajn plánovanie"
            title="Webdizajn plánovanie"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={navrhImg}
            alt="Webdizajn návrh"
            title="Webdizajn návrh"
          />
          <div className="solution-description">
            <h3>3. Návrh</h3>
            <p>
              V tejto etape sa vytvára vizuálny návrh stránky.{" "}
              <b>Ide o grafické zobrazenie</b> toho, ako bude stránka vyzerať.{" "}
              <b>Klient má možnosť vidieť návrh</b> a poskytnúť spätnú väzbu, na
              základe ktorej sa <b>návrh môže upraviť</b>.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>4. Vývoj</h3>
            <p>
              Po schválení návrhu sa{" "}
              <b>začne s technickým vývojom stránky. Programátori kódujú</b>{" "}
              stránku, <b>grafici pridávajú</b> vizuálne <b>prvky</b> a
              <b> copywriteri píšu</b> texty. Všetko sa koordinuje tak,{" "}
              <b>aby stránka bola funkčná a vizuálne atraktívna</b>.
            </p>
          </div>
          <img
            className="img solution-img"
            src={vyvojImg}
            alt="Webdizajn vývoj a programovanie"
            title="Webdizajn vývoj a programovanie"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={testovanieImg}
            alt="Webdizajn testovanie a kontrola"
            title="Webdizajn testovanie a kontrola"
          />
          <div className="solution-description">
            <h3>5. Testovanie</h3>
            <p>
              <b>Pred</b> oficiálnym <b>spustením</b> stránky{" "}
              <b>sa vykoná séria testov</b>. Testuje sa, ako stránka funguje{" "}
              <b>na rôznych zariadeniach</b> (počítače, tablety, mobily) a{" "}
              <b>v rôznych prehliadačoch</b> (Chrome, Firefox, Safari atď.). Ak
              sa nájdu <b>chyby, opravia</b> sa.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>6. Spustenie</h3>
            <p>
              Keď je všetko pripravené a otestované,{" "}
              <b>stránka alebo eshop sa spustí</b>. To znamená, že je teraz
              prístupná pre verejnosť. V tejto fáze sa často uskutoční aj
              marketingová kampaň, aby sa o novej stránke dozvedelo čo najviac
              ľudí.
            </p>
          </div>
          <img
            className="img solution-img"
            src={spustenieImg}
            alt="Webdizajn spustenie webstránky a eshopu"
            title="Webdizajn spustenie webstránky a eshopu"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={udrzbaImg}
            alt="Webdizajn servis, monitoring a údržba"
            title="Webdizajn servis, monitoring a údržba"
          />
          <div className="solution-description">
            <h3>7. Údržba</h3>
            <p>
              Spustenie stránky nie je koniec.{" "}
              <b>Je potrebné ju pravidelne aktualizovať</b>, pridávať nový obsah{" "}
              <b>a sledovať, ako stránka funguje</b>. To <b>zahŕňa</b> aj{" "}
              <b>monitorovanie</b> návštevnosti a správanie sa užívateľov na
              stránke, aby sa mohli robiť ďalšie vylepšenia.
            </p>
          </div>
        </div>
      </section>

      <References />

      <ZonaClankySlider clanky={pageQuery.data.articles} />

      <section id="zonaFaq">
        <h2>
          Často kladené otázky
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <p>
          Prinášame najčastejšie otázky pri webdizajne webových stránok a
          e-shopov.
        </p>
        <br />
        <br />

        <ZonaFaqQuestion
          question="Koľko to bude stáť?"
          answer={
            <>
              Toto je jedna z najčastejších otázok. Cena závisí od mnohých
              faktorov, ako sú komplexnosť stránky, počet funkcií, grafického
              dizajnu, či to bude. Šablónové riešenie alebo na mieru a podobne.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako dlho potrvá webdizajn?"
          answer={
            <>
              Časový rámec je ďalším kľúčovým faktorom. Závisí od rozsahu
              projektu, počtu revízií a rýchlosti komunikácie medzi vami a naším
              vývojovým tímom.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Aké technológie budete používať?"
          answer={
            <>
              Využívame overené technológie CMS ako Wordpress alebo nami
              navrhnuté riešenia na mieru. Niektoré jednoduchšie webdizajny
              dokonca nepotrebujú žiadne CMS. V prípade programovacích jazykov
              využívame rôzne jazyky a frameworky.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako bude stránka alebo e-shop optimalizovaný pre vyhľadávače (SEO)?"
          answer={
            <>
              SEO je dôležitý aspekt, a klienti často chcú vedieť, ako bude ich
              stránka optimalizovaná, aby sa zobrazovala vyššie vo
              vyhľadávačoch. Využívame On-Page SEO, Off-Page SEO a Technické SEO
              v spolupráci s externým SEO konzultantom s 18-ročnými
              skúsenosťami.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Bude stránka responzívna?"
          answer={
            <>
              U nás je to štandard od roku 2015. Dodatočné prispôsobenie však
              robíme na vyžiadanie pre tablety.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako bude prebiehať údržba?"
          answer={
            <>
              Údržba, servis a monitoring závisia hlavne od veľkosti a
              kritickosti projektu. Iné nastavenie má eshop, ktorý musí fungovať
              24-7 a iné prezentačná stránka na ktorú sa stačí pozrieť 1x za 6
              mesiacov, prípadne ad hoc.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako budem môcť aktualizovať obsah?"
          answer={
            <>
              V prípade, že si chcete spravovať svoj obsah, tak vyhotovíme
              riešenie, ktorým si budete vedieť meniť fotky, texty, ceny,
              prípadne iné požadované položky.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Je možné integrovať sociálne médiá alebo iné platformy?"
          answer={
            <>
              Integrácia s platformami ako Facebook, Instagram, YouTube alebo
              e-mailovými službami je zákazníkmi často požadovaná, takže áno.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako bude prebiehať platobný proces v e-shope?"
          answer={
            <>
              Po integrácií platobnej brány a prepojenia s účtovným, skladovým,
              či ERP softvérom bude prebiehať platobný proces štandardne ako na
              väčšine eshopov.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako budeme merať úspech stránky?"
          answer={
            <>
              Do stránky vieme integrovať meracie nástroje ako Google Analytics,
              ktorý poskytuje detailné informácie, teplotné mapy, či iné
              nástroje.
            </>
          }
        />
      </section>
      <ContactUs
        text={<>Máte záujem o webstránku alebo e-shop?</>}
        button="Napíšte nám e-mail"
      />
    </Layout>
  );
};

export default Webdizajn;
